<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="mining"></submenu-server>

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <!-- page title -->
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="b in item.body">

            <div v-if="b.type === 'heading'" class="heading">
              <div class="clear"></div>
              <div v-if="b.value === 'Inquiry Button'">
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/> <br/>
              </div>
              <div v-else>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="paragraph">
              <p v-html="b.value"></p>
            </div>

            <div v-else-if="b.type === 'image'">
              <img
              :src="b.value.large.src"
              :width="b.value.large.width"
              :height="b.value.large.height">
            </div>
            
            <!-- stream block -->
            <div v-else-if="b.type === 'stream_block'"> 
              <div v-for="sb in b.value">

                <!-- images at the page bottom -->
                <div v-if="sb.value.name === 'footer'">
                  <div v-for="itm in sb.value.image_stream" class="float-start pad">
                    <img :src="itm.value.large.src"
                    height="380">
                  </div>
                </div>

                <!-- Manli GPU Mining motherboard and server-->
                <div v-else class="float-start block">
                  <div v-for="(itm, index) in sb.value.image_stream">
                    <div v-if="index === 0">
                      <div v-if="sb.value.name==='product'">
                      <img :src="itm.value.large.src"
                        height="200">
                      </div>
                      <div v-else>
                        <img :src="itm.value.large.src"
                        height="300">
                      </div>
                    </div>
                    <div v-else>
                      <img :src="itm.value.large.src"
                        height="50" class="float-end">
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div v-if="sb.value.heading" class="heading">{{sb.value.heading}}</div>
                  <div v-if="sb.value.paragraph" v-html="sb.value.paragraph" class="paragraph"></div>
                </div>
                
                
              </div>
              <div class="clear"></div>
              <br/><br/><br/>
            </div>
            <!-- end of stream block-->

          </div>
        
        <!-- end of page content -->
      </div>

  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'MiningSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Mining Solutions", link: "MiningSolutions"})

    getAPI
    .get('/content/pages/43')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.pad {margin-right:50px;}
.block {width:45%; margin: 20px 30px 0px 0;}
.block .heading {border-bottom: 1px solid #333; font-size:25px; }
.block .paragraph {margin: 30px 0 0 0; }
</style>